<template>
  <div>
    <loader
      object="#ffffff"
      color1="#ffffff"
      color2="#ffffff"
      time="100"
      size="5"
      speed="2"
      bg="#5e151d"
      objectbg="#999793"
      opacity="100"
      :name="selected"
      disableScrolling="false"
    ></loader>
    <div class="container" style="min-height: 150px">
      <div class="card">
        <div class="card-header">
          <h1><b-icon icon="calendar2-week" /> Gestión de Ciclos Académicos</h1>
        </div>
        <div class="card-body">
          <div class="container">
            <b-row>
              <b-col><PeriodRegister /></b-col>
              <b-col>
                <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                  <b-input-group-prepend is-text>
                    <b-icon icon="search"></b-icon>
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="keyword"
                    placeholder="Buscar"
                    type="text"
                  /> </b-input-group
              ></b-col>
            </b-row>
          </div>
          <div class="container col-12">
            <div>
              <b-table
                striped
                responsive
                stacked="lg"
                :items="items"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                :keyword="keyword"
              >
                <template #cell(modality)="row">
                  <em>{{ returnModality(row.item.modality) }}</em>
                </template>
                <template #cell(state)="row">
                  <b-badge pill :variant="returnColor(row.item.state)">{{
                    returnData(row.item.state)
                  }}</b-badge>
                </template>
                <template #cell(programs)="row">
                  <b-icon
                    type="button"
                    class="mx-1"
                    @click="toPrograms(row.item.identifier)"
                    icon="clipboard-check"
                  />
                  <span>{{ row.item.countPrograms }}</span>
                </template>
                <template #cell(actions)="row">
                  <b-icon-pencil-square
                    type="button"
                    size="sm"
                    class="mx-1"
                    @click="dateItem(row.item)"
                  ></b-icon-pencil-square>

                  <b-icon-trash
                    type="button"
                    size="sm"
                    class="mx-1"
                    @click="modalDelete(row.item)"
                  ></b-icon-trash>
                </template>
              </b-table>
              <b-pagination
                style="display: flex; justify-content: center"
                v-model="currentPage"
                pills
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <b-modal scrollable centered v-model="show" title="Editar Ciclo">
        <b-container fluid>
          <!--Contenido modal-->
          <form>
            <AlertError ref="error" />
            <div class="form-group">
              <label for="exampleInputEmail1">Nombre</label>
              <input
                type="text"
                class="form-control"
                placeholder="Ingrese su nombre"
                v-model="data.name"
                required
              />
            </div>
            <div class="form-group my-3">
              <label for="exampleInputPassword1">Año</label>
              <input
                type="number"
                class="form-control"
                v-model="data.year"
                placeholder="Ingrese año"
                required
              />
            </div>
            <div class="form-group my-3">
              <label for="exampleInputPassword1">Descripción</label>
              <b-form-textarea
                class="form-control"
                v-model="data.description"
                placeholder="Ingrese descripción"
                required
              />
            </div>
            <div class="form-group my-3">
              <label for="exampleInputEmail1">Modalidad</label>
              <b-form-select
                class="form-control"
                v-model="data.modality"
                :options="modalidad"
                required
              ></b-form-select>
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">Estado</label>
              <b-form-select
                class="form-control"
                v-model="data.state"
                :options="states"
                required
              ></b-form-select>
            </div>
          </form>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="saveEditItem(data)"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Editar
            </button>
            <button
              type="button"
              @click="exit()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
    <!--Mensaje alerta eliminacion-->

    <div>
      <b-modal centered v-model="showDelete" title="Mensaje">
        <b-container fluid>
          <!--Contenido modal-->
          <h5 class="text-center">
            ¿Seguro que desea eliminar a <b>{{ data.name }}</b
            >?
          </h5>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="deleteItem(dataDelete)"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Aceptar
            </button>
            <button
              type="button"
              @click="exitDelete()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { uuid } from "vue-uuid";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import PeriodRegister from "../File-period/Periods-register.vue";
import AlertError from "../../components/AlertError.vue";
export default {
  components: { PeriodRegister, AlertError },
  data: () => ({
    selected: "",
    keyword: "",
    perPage: 10,
    currentPage: 1,
    imageUrl: "",
    imageData: null,
    picture: null,
    uploadValue: 0,
    dataDelete: "",
    show: false,
    showDelete: false,
    data: {
      id: "",
      identifier: "",
      year: null,
      name: "",
      description: "",
      modality: "",
      state: "",
    },
    modalidad: [
      { text: "Virtual", value: "V" },
      { text: "Presencial", value: "P" },
    ],
    states: [
      { text: "Activo", value: "A" },
      { text: "Inactivo", value: "I" },
    ],
    estados: [
      { value: false, name: "Activo" },
      { value: true, name: "Inactivo" },
    ],
    fields: [
      { key: "name", label: "Nombre" },
      { key: "year", label: "Año Académico" },
      { key: "modality", label: "Modalidad" },
      { key: "programs", label: "Programas" },
      { key: "state", label: "Estado" },
      { key: "actions", label: "Acciones" },
    ],
    desserts: [],
  }),

  created() {
    this.initialize();
  },
  computed: {
    rows() {
      return this.desserts.length;
    },
    items() {
      this.currentPage = 1;
      return this.keyword
        ? this.desserts.filter(
            (item) =>
              item.name.toLowerCase().includes(this.keyword.toLowerCase()) ||
              item.year
                .toString()
                .toLowerCase()
                .includes(this.keyword.toLowerCase())
          )
        : this.desserts;
    },
  },

  methods: {
    returnModality(data) {
      if (data === "V") return "Virtual";
      else if (data === "P") return "Presencial";
    },
    previewImage(event) {
      this.uploadValue = 0;
      this.picture = null;
      this.imageData = event.target.files[0];
    },
    //Se obtiene la terminacion segun el nombre
    getFileExtension(filename) {
      return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
    },

    onUpload() {
      this.picture = null;
      /*var nameUrl =
        uuid.v1() + "." + this.getFileExtension(`${this.imageData.name}`);*/
      let nameUrl = null;
      if (this.data.image === "") {
        nameUrl = uuid.v1();
      } else {
        nameUrl = this.data.image;
      }
      const storageRef = firebase
        .storage()
        //uuid genera claves unicas
        .ref(nameUrl)
        .put(this.imageData);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.picture = url;
          });
        }
      );
      return nameUrl;
    },
    toPrograms(data) {
      this.$router.push("/academic/" + data + "/programs");
    },
    returnData(data) {
      if (data === "A") return "Activo";
      else if (data === "I") return "Inactivo";
    },
    returnColor(data) {
      if (data === "A") return "success";
      else if (data === "I") return "danger";
    },
    exit() {
      this.show = false;
    },
    exitDelete() {
      this.showDelete = false;
      this.data.username = "";
    },
    modalDelete(data) {
      this.showDelete = true;
      this.dataDelete = data;
      this.data.name = data.name;
    },
    async initialize() {
      this.selected = "circular";
      let res = await this.$axios
        .get("/academic", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          var aux = [];
          for (var i in res.data) {
            var obj = res.data[i];
            aux.push(obj);
          }
          this.desserts = aux;
          this.selected = "";
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
    async dateItem(item) {
      this.show = true;
      let res = await this.$axios.get("/academic/cycle-edit?id=" + item.id, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      });
      this.data = res.data;
    },
    async saveEditItem(item) {
      this.show = true;
      if (item.id != "") {
        try {
          await this.$axios
            .post("/academic/cycle-edit", this.data, {
              headers: {
                Authorization: `Bearer ${localStorage.token}`,
              },
            })
            .then((res) => {
              this.$message.success(res.data.content);
              this.show = false;
              this.initialize();
            })
            .catch((e) => {
              this.$refs.error.showAlert(e.response.data.content);
            });
        } catch (e) {
          console.log(e);
        }
      }
    },
    async deleteItem(item) {
      this.showDelete = false;
      if (item != "") {
        try {
          await this.$axios
            .get("/academic/cycle-delete?id=" + item.id, {
              headers: {
                Authorization: `Bearer ${localStorage.token}`,
              },
            })
            .then((res) => {
              this.$message.success(res.data.content);
              this.initialize();
            });
        } catch (e) {
          this.$message.error("Error!");
          console.log(e);
        }
      }
    },
  },
};
</script>
<style>
.card-header {
  background-color: rgb(119, 35, 45);
  color: white;
}
.modal-content {
  background-color: rgb(243, 243, 243);
}
</style>