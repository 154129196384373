<template>
  <div id="PeriodRegister">
    <button
      @click="addProgram()"
      style="background-color: #1d425e; color: white"
      class="btn mb-3"
    >
      Agregar Ciclo Académico
    </button>
    <div>
      <b-modal
        scrollable
        centered
        v-model="show"
        title="Aperturar Ciclo Académico"
      >
        <b-container fluid>
          <!--Contenido modal-->
          <form>
            <b-alert
              :show="dismissCountDown"
              variant="danger"
              @dismiss-count-down="countDownChanged"
            >
              <strong>Error. {{ error }},</strong> ocultando en
              {{ dismissCountDown }} segundos...
            </b-alert>
            <b-row>
              <div class="form-group col-7">
                <label for="validationServer01">Nombre</label>
                <b-form-input
                  :state="data.name != ''"
                  type="text"
                  class="form-control"
                  placeholder="Ingrese su nombre"
                  v-model="data.name"
                />
              </div>
              <div class="form-group col-5">
                <label for="exampleInputPassword1">Año</label>
                <b-form-input
                  :state="data.year != null && data.year != ''"
                  type="number"
                  class="form-control"
                  v-model="data.year"
                  placeholder="Ingrese año"
                />
              </div>
            </b-row>
            <div class="form-group">
              <label for="exampleInputEmail1">Modalidad</label>
              <b-form-select
                class="form-control"
                v-model="data.modality"
                :options="modalidades"
              ></b-form-select>
            </div>
            <!-- Registro en bloque oculto
              <div class="form-group">
              <b-form-checkbox
                id="checkbox-1"
                v-model="data.blockRegistration"
                name="checkbox-1"
                value="true"
                unchecked-value="false"
              >
                Acepto el registro en bloque
                <b-icon-info-circle-fill
                  color="rgb(119, 35, 45)"
                  v-b-popover.hover.top="
                    'Se realiza el registro de todos los programas activos dentro del ciclo que se esta creando.'
                  "
                />
              </b-form-checkbox>
            </div>-->
            <div v-show="data.blockRegistration === 'true'">
              <div class="form-group my-3">
                <label for="exampleInputPassword1">Fecha de inicio</label>
                <input
                  type="date"
                  class="form-control"
                  v-model="data.dateOpening"
                  required
                />
              </div>
              <div class="form-group my-3">
                <label for="exampleInputPassword1"
                  >Fecha de cierre de matricula</label
                >
                <input
                  type="date"
                  class="form-control"
                  v-model="data.dateClosingEnrollmet"
                  required
                />
              </div>
              <div class="form-group my-3">
                <label for="exampleInputPassword1">Fecha de fin</label>
                <input
                  type="date"
                  class="form-control"
                  v-model="data.dateClosing"
                  required
                />
              </div>
              <div class="form-group my-3">
                <label for="exampleInputPassword1">Monto matricula</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="data.payEnrollmet"
                  placeholder="Ingrese monto de dinero"
                  required
                />
              </div>
              <div class="form-group my-3">
                <label for="exampleInputPassword1">Monto pensión</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="data.payPension"
                  placeholder="Ingrese monto de dinero"
                  required
                />
              </div>
            </div>
          </form>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="saveAddItem(data)"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Enviar
            </button>
            <button
              type="button"
              @click="exit()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
export default {
  name: "PeriodRegister",
  data() {
    return {
      selected: "",
      error: "",
      dismissSecs: 2,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      show: false,
      data: {
        name: "",
        modality: "",
        year: null,
        blockRegistration: false,
        dateOpening: "",
        dateClosing: "",
        dateClosingEnrollmet: "",
        payEnrollmet: null,
        payPension: null,
      },
      modalidades: [
        { text: "Modalidad Virtual", value: "V" },
        { text: "Modalidad Presencial", value: "P" },
      ],
    };
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(data) {
      this.error = data;
      this.dismissCountDown = this.dismissSecs;
    },
    exit() {
      this.show = false;
    },
    addProgram() {
      (this.data = {
        name: "Ciclo Académico",
        modality: "V",
        year: null,
        blockRegistration: false,
        dateOpening: "",
        dateClosing: "",
        dateClosingEnrollmet: "",
        payEnrollmet: null,
        payPension: null,
        //ojo
        weeks: 0,
      }),
        (this.show = true);
    },
    async saveAddItem() {
      if (!this.data.blockRegistration) {
        this.data.dateOpening = "2000-01-01";
        this.data.dateClosing = "2000-01-01";
        this.data.dateClosingEnrollmet = "2000-01-01";
        this.data.payEnrollmet = 0;
        this.data.payPension = 0;
      }
      await this.$axios
        .post("/academic/cycle-register", this.data, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.show = false;
          this.$message.success(res.data.content);
          this.$parent.initialize();
        })
        .catch((e) => {
          console.log(e);
          this.showAlert(e.response.data.content);
        });
    },
  },
};
</script>
<style>
.modal-content {
  background-color: rgb(243, 243, 243);
}
</style>